import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, getDocs, query, orderBy, limit } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyBRJhd4W7vFhyUaSZksci8_xxWeCys02yA",
  authDomain: "first-million-images.firebaseapp.com",
  projectId: "first-million-images",
  storageBucket: "first-million-images.appspot.com",
  messagingSenderId: "819703447767",
  appId: "1:819703447767:web:af8a19e99af8928509b092",
  measurementId: "G-MW6R19JQQ0"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);

export { db, storage, auth, functions };

export const createCardDocument = async (data) => {
  const cardNumber = await getNextCardNumber();

  const docRef = await addDoc(collection(db, 'cards'), {
      uid: crypto.randomUUID(),
      card_number: cardNumber,
      imageUrl: data.imageUrl,
      name: data.name,
      surname: data.surname,
      description: data.description,
      created_at: new Date()
  });

  return docRef.id;
};

export const getNextCardNumber = async () => {
  const q = query(collection(db, 'cards'), orderBy('card_number', 'desc'), limit(1));
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
      const lastCard = querySnapshot.docs[0].data();
      return lastCard.card_number + 1;
  } else {
      return 1;
  }
};
