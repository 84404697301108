import React, { useState } from 'react';
import { createCardDocument, storage } from '../firebase/firebaseConfig';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Button, Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import { LeftCircleOutlined } from '@ant-design/icons';
import './CreateCard.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Payment from '../components/Payment';

const { TextArea } = Input;

const stripePromise = loadStripe('pk_test_51PfjnNJCTMMIZGdGXPw8Y4cOwwSjHmIzO7pj279ayeOEs62bd4HomtppKp4cPPiZZDcii6NumdTP0KLFplQ1LU5E00fZ6CBWbr');

const CreateCard = () => {
    const [form] = Form.useForm();
    const [image, setImage] = useState(null);
    const [paymentCompleted, setPaymentCompleted] = useState(false); // Nuevo estado para controlar el pago

    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0]);
        }
    };

    const handleSubmit = async (values) => {
        if (!paymentCompleted) {
            alert('Please complete the payment first.');
            return;
        }

        try {
            let imageUrl = '';
            if (image) {
                const imageRef = ref(storage, `images/${image.name}`);
                await uploadBytes(imageRef, image);
                imageUrl = await getDownloadURL(imageRef);
            }

            const cardData = {
                name: values.name,
                surname: values.surname,
                description: values.description,
                imageUrl, // Add the image URL here
            };

            const docId = await createCardDocument(cardData);
            alert(`Card created with ID: ${docId}`);
            form.resetFields(); // Reset form fields after submission
            setImage(null); // Clear the image state
            setPaymentCompleted(false); // Reset payment state
        } catch (error) {
            console.error("Error creating card: ", error);
            alert('Error creating card');
        }
    };

    return (
        <section className='create'>
            <header className="header-create">
                <Link to="/" style={{ color: "#EFE6F7" }}>
                    <LeftCircleOutlined style={{ fontSize: '40px' }} />
                </Link>
                <h1>Create</h1>
            </header>
            <section className='mid-section'>

                <div className='payment-section'>
                    <p className='text-payment'>With the absurd amount of 1€ you can post your image!</p>
                    <p>Esto esta en fase beta asi que usa la targeta 4242 4242 4242 4242 y los demas numeros inventados para publicar tu imagen, aprovecha ahora que e gratis!</p>
                    <Elements stripe={stripePromise}>
                        <Payment onPaymentComplete={() => setPaymentCompleted(true)} />
                    </Elements>
                </div>

                <div className='form-section'>
                    <Form
                        form={form}
                        onFinish={handleSubmit}
                        className='form'
                        layout="vertical"
                    >
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[{ required: true, message: 'Please enter your name' }]}
                        >
                            <Input placeholder="Name" className='from-background'/>
                        </Form.Item>

                        <Form.Item
                            name="surname"
                            label="Surname"
                            rules={[{ required: true, message: 'Please enter your surname' }]}
                        >
                            <Input placeholder="Surname" className='from-background' />
                        </Form.Item>

                        <Form.Item
                            name="description"
                            label="Description"
                            rules={[{ required: true, message: 'Please enter a description' }]}
                        >
                            <TextArea placeholder="Description" className='from-background'/>
                        </Form.Item>

                        <Form.Item label="Image">
                            <Input
                                type="file"
                                onChange={handleImageChange}
                                className='input-image'
                                required
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className='button-create'
                                disabled={!paymentCompleted}
                            >
                                Create Card
                            </Button>
                        </Form.Item>
                    </Form>
                </div>

                
            </section>
        </section>
    );
};

export default CreateCard;
