import React, { useState } from "react";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { functions } from '../firebase/firebaseConfig';
import "./Payment.css";
import { httpsCallable } from "firebase/functions";

const Payment = ({ onPaymentComplete }) => { 
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const createPaymentIntent = httpsCallable(functions, 'createPaymentIntent');
            const response = await createPaymentIntent({ amount: 100, currency: 'eur' });
            console.log('Response received:', response);

            // Confirm the payment using Stripe
            const result = await stripe.confirmCardPayment(response.data.clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                },
            });

            if (result.error) {
                console.error('Payment error:', result.error.message);
                alert(result.error.message);
            } else {
                if (result.paymentIntent.status === 'succeeded') {
                    alert('Payment succeeded!');
                    onPaymentComplete(); 
                }
            }
        } catch (error) {
            console.error('Error in payment process:', error.message);
            alert(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <CardElement className="card-element" />
            <button type="submit" disabled={!stripe || loading} className="button-pay">
                {loading ? 'Processing...' : 'Pay 1€'}
            </button>
        </form>
    );
}

export default Payment;
