import React from "react";
import './Card.css';
import { Link } from 'react-router-dom';

function Card({ uid, number, imageUrl, name, surname, description }) {

    const add0IfSingleNumberCard = () => {
        if (number < 10) {
            return `0${number}`;
        } else {
            return number;
        }
    }

    const formattedNumber = add0IfSingleNumberCard();

    return (
        <Link to={`/details/${uid}`} state={{ formattedNumber, imageUrl, name, surname, description }}>
            <div className="rectangle">
                <img src={imageUrl} alt={`${name} ${surname}`} className="card-image" />
                <div className="overlay">
                    <span className="overlay-number">{formattedNumber}</span>
                    <span className="overlay-title">{name} {surname}</span>
                </div>
            </div>
        </Link>
    );
}

export default Card;
