import React from "react";
import "./About.css"
import { LeftCircleOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';

const About = () => {
    return (
        <section className="about">
            <header className="header-about">
                <Link to="/" style={{ color:"#EFE6F7" }}>
                    <LeftCircleOutlined style={{ fontSize: '40px' }} />
                </Link>
                <h1>About Us</h1>
            </header>
        
            <div className="paragraph">
                <p className="intro">Hi, my name is Pol Mauri. I am currently living in Barcelona, Spain. For the past two years, I have been deeply immersed in the world of programming, dedicating countless hours to studying and honing my skills.</p>
                <p>Throughout this time, I have engaged in a variety of personal projects, ranging from simple web applications to more complex software solutions. These projects have not only solidified my understanding of programming concepts but have also provided me with practical experience in problem-solving and project management. Currently, I am actively seeking opportunities to showcase my skills and connect with the tech community. Whether it’s through contributing to open-source projects, participating in hackathons, or networking with industry professionals, I am eager to make a mark in this dynamic and ever-evolving field.</p>
                <br />
                <p>I need to stop yapping...</p>
            </div>

            <footer>
                <hr className="line"/>
                <div className="footer-text">
                    <p>00</p>
                    <p>POL MAURI</p>
                </div>

            </footer>
        </section>
    )
}

export default About;