import React, { useEffect, useState } from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import CardList from '../components/CardList';
import { getNextCardNumber } from '../firebase/firebaseConfig';

function Home() {

  const [numberOfCards, setNumberOfCards] = useState(0);

  useEffect(() => {
    const fetchNumberOfCards = async () => {
      const nextCardNumber = await getNextCardNumber();
      setNumberOfCards(nextCardNumber - 1);
    };

    fetchNumberOfCards();
  }, []);

  return (
    <section className="Home">
      <header className="Home-header">
        
        <h1 className='Logo-small'>Hall of Images.</h1>

        <nav className='Home-nav'>
          <Link to="/create">Get one!</Link>
          <Link to="/about">About us!</Link>
          <a>{numberOfCards} / 1M</a>
          <Tooltip title="search">
            <Button shape="circle" style={{ background: "#EFE6F7" }} icon={<SearchOutlined />} />
          </Tooltip>          
        </nav>
      </header>

      <div className="card-list-container">
        <CardList />
      </div>
    </section>

  );
}

export default Home;
