import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './screens/Home';
import About from './screens/About';
import CreateCard from './screens/CreateCard';
import CardDetails from './screens/CardDetails';

const App = () => {
  return (
    <Router>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/create" element={<CreateCard />} />
            <Route path='/details/:uid' element={<CardDetails />} />
        </Routes>
    </Router> 
  );
}

export default App;
