import React, { useState, useEffect } from "react";
import Card from "./Card";
import { db } from "../firebase/firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import "./CardList.css";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";

const cardPerPage = 3;

const CardList = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [cards, setCards] = useState([]);

    useEffect(() => {   
        const fetchCards = async () => {
            const querySnapshot = await getDocs(collection(db, 'cards'));
            const cardsData = querySnapshot.docs.map(doc => doc.data());
            cardsData.sort((a, b) => a.card_number - b.card_number);
            setCards(cardsData);
        };

        fetchCards();
    }, []);

    const start = (currentPage - 1) * cardPerPage;
    const end = start + cardPerPage;
    const currentCards = cards.slice(start, end);


    const handleNextPage = () => {
        if (end < cards.length) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (start > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    

    return (
        <div className='card-list'>
            <div className='cards-container'>
                <LeftCircleOutlined style={{ fontSize: '40px' }} onClick={handlePreviousPage} disabled={start === 0} />

                {currentCards.map((card, index) => (
                    <Card 
                        key={index} 
                        uid={card.uid}
                        number={card.card_number} 
                        imageUrl={card.imageUrl} 
                        name={card.name} 
                        surname={card.surname} 
                        description={card.description} 
                    />
                ))}

                <RightCircleOutlined style={{ fontSize: '40px' }} onClick={handleNextPage} disabled={end >= cards.length} />

            </div>
        </div>
    );
};

export default CardList;
