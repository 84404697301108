import React from "react";
import './CardDetails.css';
import { Link, useLocation } from 'react-router-dom';
import { LeftCircleOutlined } from "@ant-design/icons";

const CardDetails = () => {
    
    //const { uid } = useParams();
    const location = useLocation();
    const { imageUrl, name, surname, description, formattedNumber } = location.state || {};
    
    
    return (
        <section className="details">
            <header className="header-details">
                <Link to="/" style={{ color:"#EFE6F7" }}><LeftCircleOutlined style={{ fontSize: '40px' }} /></Link>
                <h1>Details</h1>
            </header>

            <div className="middle-section">
                <img src={imageUrl} className="details-image" />
                <div className="description">
                    <h1>Description:</h1>
                    <p>{description}</p>
                </div>
            </div>

            <footer>
                <hr className="line"/>
                <div className="footer-text">
                    <p>{formattedNumber}</p>
                    <p>{name} {surname}</p>
                </div>

            </footer>
        </section>
    );
}

export default CardDetails;